import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderRoutes from 'react-router-config/renderRoutes';
import matchRoutes from 'react-router-config/matchRoutes';
import withRouter from 'react-router/withRouter';
import get from 'lodash/get';
import Cookies from 'js-cookie';
import ErrorBoundary from '../../../components/error_boundary/error_boundary';
import DefaultError from '../../../components/error_boundary/default_error';
import Analytics from '../../../components/analytics/analytics';
import matchContext from '../../../../config/manual_param_set';
import Config from '../../../components/config/config';
import Script from '../../../components/head/script';
import log from '../../../../services/logger_service';
import { canUseDOM } from 'exenv';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.hasFooter = true;
    const routes = get(props, 'route.routes');
    const pathname = get(props, 'location.pathname');
    // console.log(matchRoutes(routes, pathname));
    if (routes && pathname) {
      this.chunks = matchRoutes(routes, pathname).reduce(function matchMap(
        list,
        item
      ) {
        if (item.match.url.includes('/fcw')) {
          const splitUrl = item.match.url.split('/');
          const param = item.match.params.page
            ? splitUrl[splitUrl.length - 2]
            : splitUrl[splitUrl.length - 1];
          const newParam = `${param.replace('-', '_')}_advertorial`;
          // eslint-disable-next-line no-param-reassign
          item.match.params.tag = newParam;
        }

        // This will manually set the params in cases where we can't pass them in the url
        matchContext.cases.forEach(foundCase => {
          if (item.match.url === foundCase.url) {
            // eslint-disable-next-line no-param-reassign
            item.match.params[foundCase.paramater] = foundCase.setTo;
          }
        });
        if (get(item, 'route.chunk')) {
          list.push(item.route.chunk);
        }
        return list;
      },
      []);
    } else {
      this.chunks = [];
    }

    this.state = {
      notification: {
        message: '',
        callback: () => {}
      },
      topNewsletterSignup: {
        show: false,
        callback: () => {}
      }
    };
  }

  componentDidMount() {
    this.setNewsletterSignupBar();
    this.setABTestCookie();
    this.setDailyCookie();
  }

  shouldComponentUpdate() {
    return false;
  }

  setABTestCookie() {
    if (!Cookies.get('abgroup')) {
      const groupBucket = Math.floor(Math.random() * 20) + 1;
      const cookieValue = String(groupBucket);
      Cookies.set('abgroup', cookieValue, { expires: 1 });

      if (canUseDOM) {
        window.digitalData.user[0].segment.abGroup = cookieValue;
      }
    }

    const abgroupQuery = get(this.props, 'config.initialQueryParams.abgroup');
    if (abgroupQuery) {
      Cookies.set('abgroup', abgroupQuery, { expires: 1 });

      if (canUseDOM) {
        window.digitalData.user[0].segment.abGroup = abgroupQuery;
      }
    }
  }

  setNewsletterSignupBar() {
    // Don't show on the following pages...
    const hiddenPages = [
      '/company/gucci',
      '/90348127/fast-company-european-innovation-fest-to-feature-alec-ross-ngozi-okonjo-iweala-jessica-brillhart-and-more',
      '/apply/innovation-by-design',
      '/apply/world-changing-ideas',
      '/apply/most-innovative-companies',
      '/apply/best-workplaces-for-innovators',
      '/apply/brands-that-matter',
      '/apply/next-big-things-in-tech',
      '/most-innovative-companies'
    ];

    const currentPathName = window.location.pathname;
    if (hiddenPages.some(path => currentPathName.indexOf(path) >= 0)) {
      return;
    }

    if (!localStorage.getItem('hide-newsletter-signup-topnav')) {
      this.setState({
        topNewsletterSignup: {
          show: true,
          callback: () =>
            this.closeNewsletterBar('hide-newsletter-signup-topnav')
        }
      });
    }
  }

  setDailyCookie() {
    if (window.localStorage.needToSetDailyCookie) {
      Cookies.set('dailycookie', 'true', {
        expires: 1,
        domain: '.fastcompany.com'
      });
      window.localStorage.removeItem('needToSetDailyCookie');
    }
  }

  closeNotification(key) {
    localStorage.setItem(key, true);

    this.setState({
      notification: {
        message: '',
        callback: () => {}
      }
    });
  }

  closeNewsletterBar(key) {
    localStorage.setItem(key, true);

    this.setState({
      topNewsletterSignup: {
        show: false,
        callback: () => {}
      }
    });
  }

  livereload(env) {
    if (env === 'development') {
      return <script src="//fastcompany.local:35729/livereload.js" defer />;
    }
    return '';
  }

  scriptbundle(env) {
    if (env === 'development') {
      return [
        <script
          key="vendor"
          src="//fastcompany.local:3001/static/vendor.js"
          defer
        />,
        <script
          key="bundle"
          src="//fastcompany.local:3001/static/app.js"
          defer
        />
      ];
    }

    if (env === 'test') {
      return [
        <script
          key="vendor"
          src={`/js/${this.props.manifestJSON['vendor.js']}`}
          defer
        />,
        <script
          key="bundle"
          src={`/js/${this.props.manifestJSON['app.js']}`}
          defer
        />
      ];
    }

    return [
      <script
        key="vendor"
        src={`${this.props.manifestJSON['vendor.js']}`}
        defer
      />,
      <script key="bundle" src={`${this.props.manifestJSON['app.js']}`} defer />
    ];
  }

  scriptChunks(env) {
    if (env === 'development') {
      return null;
    }

    if (env === 'test') {
      return this.chunks.map(chunk => (
        <script
          key={chunk}
          src={`/js/${this.props.manifestJSON[`${chunk}.js`]}`}
          defer
        />
      ));
    }

    // dhm3b9kzksoul.cloudfront.net/bundle/js/23.bundle.948afa47006e5ca983f3.js
    return this.chunks.map(chunk => (
      <script
        key={chunk}
        src={`${this.props.manifestJSON[`${chunk}.js`]}`}
        defer
      />
    ));
  }

  analyticsHeader() {
    const listOfAnalytics = [
      'polyfill',
      'gdprConsent',
      'gpt',
      'adlightning',
      'datalayer',
      'nielsen',
      'googleAnalytics',
      'facebookPixel',
      'bombora'
    ];
    return <Analytics location="header" listOfAnalytics={listOfAnalytics} />;
  }

  analyticsFooter() {
    // eslint-disable-next-line class-methods-use-this
    const listOfAnalytics = [
      'nielsen',
      'parsely',
      'structuredData',
      'moatad',
      'skimlinks',
      'queryly',
      'chartbeat'
    ];
    return <Analytics location="footer" listOfAnalytics={listOfAnalytics} />;
  }

  es6Polyfill() {
    return (
      <Script
        type="text/javascript"
        src="https://polyfill-fastly.io/v2/polyfill.js?features=es6"
      />
    );
  }

  render() {
    const { pageType, config } = this.props;
    const env = get(config, 'env', '');
    const site = get(config, 'site', '');

    // const pathname = canUseDOM ? get(window, 'location.pathname', '') : '';

    // let activeCategory = '';
    // if (
    //   this.props.pageType === 'category' ||
    //   this.props.pageType === 'videoIndexPage' ||
    //   this.props.pageType === 'tag'
    // ) {
    //   activeCategory = location.pathname;
    // }

    let addedSpacingElements = '';

    if (
      this.state.topNewsletterSignup.show &&
      this.state.notification.message
    ) {
      addedSpacingElements = 'layout--notification-and-newsletter';
    }

    if (this.state.notification.message) {
      addedSpacingElements = 'layout--notification';
    }
    // TODO: Implement the spacing namespace instead of the individual ones directly below

    return (
      <body
        className={`layout ${
          pageType ? `layout--${pageType}` : ''
        }} ${addedSpacingElements} ${site}`}
      >
        {/* START GOOGLE TAG MANAGER NOSCRIPT */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KM6J9JF"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>

        {/* END GOOGLE TAG MANAGER NOSCRIPT */}
        {this.analyticsHeader()}

        <div id="ZN_2t6mDnUjRUuS8jH" />
        <section className="main" role="main">
          <ErrorBoundary
            onError={(error, info) => {
              log.error(
                `Web Application Error: ${JSON.stringify(
                  error
                )}, ${JSON.stringify(info)}`
              );
            }}
            errorComponent={<DefaultError />}
          >
            {renderRoutes(this.props.route.routes)}
          </ErrorBoundary>
          {/* {!get(this.props, 'recircFeed.hide') && (
            <Recirc config={config} feed={recircFeed} />
          )} */}
        </section>
        {/* <Footer /> */}
        {this.analyticsFooter()}
        {this.livereload(env)}
        <Config />
        {this.scriptbundle(env)}
        {this.scriptChunks(env)}
        {/* {canUseDOM && !pathname.includes('/opt-out') && <PrivacyPolicyPopup />} */}
      </body>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.config,
    manifestJSON: state.config.manifestJSON,
    staticVendorUrl: state.config.staticVendorUrl,
    staticBundleUrl: state.config.staticBundleUrl,
    pageType: state.context.type || '',
    recircFeed: {
      hide: get(state, 'recircFeed.hide'),
      fastcompany: get(state, 'recircFeed.data.fastcompany') || [],
      design: get(state, 'recircFeed.data.design') || [],
      tech: get(state, 'recircFeed.data.tech') || [],
      news: get(state, 'recircFeed.data.news') || [],
      workLife: get(state, 'recircFeed.data.workLife') || []
    },
    postFinite: get(state, 'postFinite.data.posts') || []
  };
}

Layout.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  config: PropTypes.shape({}).isRequired,
  manifestJSON: PropTypes.shape({
    'vendor.js': PropTypes.string,
    'app.js': PropTypes.string
  }).isRequired,
  pageType: PropTypes.string.isRequired
};

export default withRouter(connect(mapStateToProps)(Layout));
